<template>
  <div class="top-nav w-100" :class="mediaSize">
    <nav class="navbar navbar-expand-md menuarea">
      <div :class="['container']">
        <template v-if="mediaSize === 'small-size'">
          <div class="menuButton" v-b-toggle.nav-menu>
            <span></span> <span></span> <span></span>
          </div>
          <a :class="['navbar-brand', 'mobile_logo', 'col-10', 'm-0', 'p-0']" :href="logoURL">
            <img :class="['img-fluid', marketerId === '' ? '' : 'marketer_logo']" :src="srcLogo" alt="logo" />
          </a>
        </template>
        <template v-else>
          <div class="header-rightbox d-flex align-items-center">
            <div class="menu">
              <div class="menuButton" v-b-toggle.nav-menu>
                <span></span> <span></span> <span></span>
              </div>
            </div>
            <ul class="d-flex align-items-center mb-0 phonebox">
              <li><a :href="`tel:${whiteLabel.vacationPhoneNumber}`"><span><i class="fa-solid fa-phone"></i></span>{{ whiteLabel.vacationPhoneNumber }} : {{ $t("top-header.vacation-abroad") }}</a></li>
              <li><a :href="`tel:${phoneNumber}`"><span><i class="fa-solid fa-phone"></i></span>{{ phoneNumber }} : {{ $t("top-header.vacation-in-israel") }}</a></li>
            </ul>
          </div>
          <a class="navbar-brand" :href="logoURL">
            <img :class="['img-fluid', marketerId === '' ? '' : 'marketer_logo']" :src="srcLogo" alt="logo" />
          </a>
        </template>
      </div>
    </nav>
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue';
import TemplateTopNav from './TemplateTopNav';

export default {
  name: 'TopNavTheme2',
  mixins: [TemplateTopNav],
  components: {
  },
  directives: {
    'b-toggle': VBToggle,
  },
};
</script>

<style scoped lang="scss">
.top-nav{
  top: 0px;
  .navbar {
    position: relative;
    padding: 0px;
  }
  .navbar-brand{
    max-width: 200px;
  }
}

.menuButton {
  width:40px;
  height:35px;
  padding:5px;
  float:right;
  display:grid;
  z-index: 200;
  position: relative;

  span {
    width: 100%;
    height: 4px;
    background: #fff;
    margin-bottom: 6px;
    float: left;
    transition: all 0.3s ease-in-out 0s;
    border-radius: 30px;

    &:nth-of-type(2) {
      width: 60%;
      float: right;
    }
    &:last-child{
      margin-bottom:0;
    }
  }
}
.top-nav.large-size{
  position: absolute;
  background: transparent;
  font-family: "Poppins", sans-serif;
  z-index: 2;
  .navbar-brand{
    margin-left: 75px;
  }
  .header-rightbox{
    position: relative;
    margin-right: 70px;
    .menu {
      background-color: #ff9500;
      padding: 20px 0px 20px 15px;
      position: relative;
      height: 80px;
      width: 60px;

      &:after {
        content: "";
        position: absolute;
        width: 2500px;
        height: 100%;
        right: -2500px;
        background-color: #ff9500;
        top: 0px;
      }
      .menuButton {
        display: block;
        margin-left: 0;
        cursor: pointer;
        border: 2px solid #fff;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        padding: 10px 8px 0;
        float: none;
        margin: 0;

        span {
          height: 2px;
          margin-bottom: 5px;
        }
      }
    }
    ul.phonebox {
      margin: 0;
      padding: 0;
      list-style: none;
      direction: ltr;
      margin-right: 30px;
      li {
        margin: 0 10px;
        a {
          color: #fff;
          span {
            min-width: 32px;
            max-width: 32px;
            margin-right: 12px;
            background-color: #fff;
            height: 32px;
            border-radius: 50%;
            color: #FF9500;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
          }
        }
      }
    }
  }
}
.top-nav.small-size{
  position: relative;
  background: #0061AB;
  padding: 30px;
  .navbar-brand{
    margin: auto !important;
  }
}

</style>
